import * as React from "react"
import Main from './reroll.js'
// markup
const getInspired = () => {
  return (
    <Main/>
  )
}

export default getInspired
